import { useState } from "react";
import Styles from "./index.module.scss";
import classnames from "classnames";
import Wrapper from "../../common/layout";

// Locales
export const ENGLISH = { value: "en-US", text: "en" };
const SPANISH = { value: "es", text: "es" };

const SUPPORTED_LOCALES = [ENGLISH, SPANISH];

function LanguageSelect({ selected, setSelected }) {
  const [isOpen, setIsOpen] = useState(false);
  const isOpenCx = isOpen ? Styles.isOpen : "";
  return (
    <div
      className={classnames(Styles.container, isOpenCx)}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className={classnames(Styles.locale, Styles.selectedLocale)}>
        <div className={classnames(Styles.icon, Styles.worldIcon)} />
        <div>{selected.text}</div>
      </div>

      <div className={classnames(Styles.localeOptionContainer, isOpenCx)}>
        {SUPPORTED_LOCALES.map((locale, key) => {
          const iconCx =
            locale.text === selected.text ? Styles.selectedIcon : "";
          return (
            <div
              className={classnames(Styles.locale, Styles.localeOption)}
              key={key}
              onClick={() => {
                setSelected(locale);
                setIsOpen(false);
              }}
            >
              <div className={classnames(Styles.icon, iconCx)} />
              <div>{locale.text}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default function LanguageSelectContainer(props) {
  return (
    <div className={Styles.position}>
      <Wrapper reversed>
        <LanguageSelect {...props} />
      </Wrapper>
    </div>
  );
}
