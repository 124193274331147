import { createClient } from "contentful";

// Configure the Contentful client with your space credentials
const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

export const getEntryByContentType = ({ contentName, locale }) => {
  return client
    .getEntries({
      content_type: contentName,
      locale,
      include: 1,
    })
    .then((response) => response.items)
    .catch(console.error);
};

export const getEntryById = (id) => {
  return client.getEntry(`${id}`).then((entry) => entry);
};
