import React from "react";
import { Map, AdvancedMarker, Pin } from "@vis.gl/react-google-maps";

const GoogleMap = ({ location }) => {
  const link = `https://maps.app.goo.gl/8ayp4WyGs86Xjxdj7`;
  const handleClick = () => {
    return window.open(link);
  };
  return (
    <Map
      defaultZoom={15}
      defaultCenter={{ lat: location.lat, lng: location.lon }}
      mapId={process.env.REACT_APP_GOOGLE_MAPS_MAP_ID}
      clickable={true}
      onClick={handleClick}
    >
      <AdvancedMarker
        position={{ lat: location.lat, lng: location.lon }}
        clickable={true}
        onClick={handleClick}
        title="Beach Break Surf Cafe"
      >
        <Pin scale={1.5} />
      </AdvancedMarker>
    </Map>
  );
};

export default GoogleMap;
