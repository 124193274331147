import { Heading, Label, Paragraph } from "../../../common/text";
import Styles from "./index.module.scss";
import GoogleMap from "../../../components/googleMap";

function Contact({ content }) {
  const {
    heading,
    description,
    contactHeading,
    contactInfo,
    locationCoordinates,
  } = content.fields;

  return (
    <div className={Styles.container}>
      <div className={Styles.mapContainer}>
        <GoogleMap location={locationCoordinates} className={Styles.image} />
      </div>
      <div className={Styles.content}>
        <div className={Styles.heading}>
          <Heading type="h2">{heading}</Heading>
        </div>
        <Paragraph>{description}</Paragraph>
        <div className={Styles.cta}>
          <Label>{contactHeading}</Label>
          <Paragraph>{contactInfo}</Paragraph>
        </div>
      </div>
    </div>
  );
}

export default Contact;
