import { useEffect, useState } from "react";
import Wrapper from "../common/layout";
import { getEntryByContentType } from "../util/service";
import Styles from "./index.module.scss";
import AboutOwners from "./sections/about";
import AboutCoffee from "./sections/coffee";
import Contact from "./sections/contact";
import Footer from "./sections/footer";
import Landing from "./sections/landing";
import LanguageSelect, { ENGLISH } from "../components/language";
import { Heading, Paragraph } from "../common/text";

function App() {
  const [locale, setLocale] = useState(ENGLISH);
  const [entries, setEntries] = useState(null);

  useEffect(() => {
    getEntryByContentType({
      contentName: "beachBreakSurfCafeWebsite",
      locale: locale.value,
    })
      .then((res) => setEntries(res[0].fields))
      .catch((e) => {
        console.log("Internal Error", e);
        setEntries("ERROR");
      });
  }, [locale]);

  while (!entries) {
    return (
      <div className={Styles.App}>
        <Heading>Loading</Heading>
      </div>
    );
  }

  if (entries === "ERROR") {
    return (
      <div className={Styles.App}>
        <Heading>Something went wrong &#9785; // Algo salió mal &#9785</Heading>
        <Paragraph>
          Please reach out to beachbreakcr@gmail.com for any inquiries // Por
          favor contáctenos a beachbreakcr@gmail.com para cualquier consulta
        </Paragraph>
      </div>
    );
  }

  return (
    <div className={Styles.App}>
      <LanguageSelect selected={locale} setSelected={setLocale} />
      <Wrapper>
        <Landing content={entries.landing} />
        <AboutCoffee content={entries.coffee} gallery={entries.gallery} />
        <AboutOwners content={entries.owners} />
        <Contact content={entries.contact} />
      </Wrapper>
      <Footer content={entries.footer} />
    </div>
  );
}

export default App;
