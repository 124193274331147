import Gallery from "../../../components/gallery";
import { Heading, Label, Paragraph } from "../../../common/text";
import Styles from "./index.module.scss";

function AboutCoffee({ content, gallery }) {
  const { heading, subheading, description } = content.fields;
  const { photos } = gallery.fields;
  return (
    <>
      <div className={Styles.container}>
        <div className={Styles.content}>
          <div className={Styles.heading}>
            <Heading type="h2">{heading}</Heading>
            <Label>{subheading}</Label>
          </div>
          <Paragraph>{description}</Paragraph>
        </div>
      </div>
      <Gallery content={photos} />
    </>
  );
}

export default AboutCoffee;
