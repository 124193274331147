import classnames from "classnames";
import { Label } from "../../common/text";
import Styles from "./index.module.scss";

function ButtonLink({ text, link, className }) {
  return (
    <div className={classnames(Styles.wrapper, className)}>
      <Label>
        <a
          href={link}
          rel="noreferrer"
          target="_blank"
          className={Styles.button}
        >
          {text}
        </a>
      </Label>
    </div>
  );
}

export default ButtonLink;
