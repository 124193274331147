import { Heading, Paragraph } from "../../../common/text";
import PrimaryLogo from "../../../assets/primaryLogo.svg";
import Styles from "./index.module.scss";
import Button from "../../../components/buttonLink";

function LandingSection({ content }) {
  const {
    hours,
    location,
    description,
    menuCta,
    menuPdf: {
      fields: {
        file: { url },
      },
    },
  } = content.fields;
  return (
    <div className={Styles.container}>
      <div className={Styles.logo}>
        <img src={PrimaryLogo} alt="Beach Break Surf Cafe" />
      </div>
      <div className={Styles.heading}>
        <Heading>{hours}</Heading>
        <Heading>{location}</Heading>
      </div>
      <Paragraph size="large" className={Styles.subText}>
        {description}
      </Paragraph>
      <Button text={menuCta} className={Styles.button} link={url} />
    </div>
  );
}

export default LandingSection;
