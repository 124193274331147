import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/index";
import { APIProvider } from "@vis.gl/react-google-maps";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <APIProvider
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
      onLoad={() => console.log("Maps API has loaded.")}
    >
      <App />
    </APIProvider>
  </React.StrictMode>
);
