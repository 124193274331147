import { Heading, Label, Paragraph } from "../../../common/text";
import Styles from "./index.module.scss";
import Socials from "../../../common/socials";

function About({ content }) {
  const {
    heading,
    description,
    socialsHeading,
    socials,
    photo: {
      fields: {
        file: { url },
        title,
      },
    },
  } = content.fields;

  return (
    <div className={Styles.container}>
      <div className={Styles.photoContainer}>
        <img src={url} alt={title} className={Styles.photo} />
      </div>
      <div className={Styles.content}>
        <div className={Styles.heading}>
          <Heading type="h2">{heading}</Heading>
        </div>
        <Paragraph>{description}</Paragraph>
        <div className={Styles.cta}>
          <Label>{socialsHeading}</Label>
          <Socials data={socials} />
        </div>
      </div>
    </div>
  );
}

export default About;
