import Styles from "./index.module.scss";
import CoffeeBeans from "../../assets/coffeeBeans.svg";

function CoffeeBeanPattern() {
  return (
    <div className={Styles.beans}>
      <img src={CoffeeBeans} alt="Coffee bean icon pattern" />
    </div>
  );
}

export default CoffeeBeanPattern;
