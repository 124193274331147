import classnames from "classnames";
import Styles from "./index.module.scss";

function Wrapper({ children, reversed }) {
  const reversedCx = reversed ? Styles.reverse : "";
  return (
    <div className={Styles.wrapper}>
      <div className={classnames(Styles.margin, reversedCx)}>{children}</div>
    </div>
  );
}

export default Wrapper;
