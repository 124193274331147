import Styles from "./index.module.scss";
import { memo, useEffect, useState } from "react";
import Instagram from "../../assets/instagram.svg";
import Facebook from "../../assets/facebook.svg";
import InstagramBackground from "../../assets/instagram_background.svg";
import FacebookBackground from "../../assets/facebook_background.svg";
import { getEntryById } from "../../util/service";
import { Label } from "../text";
import classnames from "classnames";

const SocialLink = memo(function SocialLink({ data, inverted }) {
  const [linkData, setLinkData] = useState(null);
  useEffect(() => {
    getEntryById(data.sys.id)
      .then((res) => {
        setLinkData(res.fields);
      })
      .catch((e) => console.log(e));
  }, [data]);

  if (!linkData) {
    return;
  }

  switch (linkData.internalName.toUpperCase()) {
    case "INSTAGRAM":
      return (
        <a href={linkData.url} target="_blank" rel="noreferrer">
          {inverted ? (
            <img src={InstagramBackground} alt={linkData.internalName} />
          ) : (
            <img src={Instagram} alt={linkData.internalName} />
          )}
        </a>
      );
    case "FACEBOOK":
      return (
        <a href={linkData.url} target="_blank" rel="noreferrer">
          {inverted ? (
            <img src={FacebookBackground} alt={linkData.internalName} />
          ) : (
            <img src={Facebook} alt={linkData.internalName} />
          )}
        </a>
      );
    default:
      return (
        <a
          className={Styles.inverted}
          href={linkData.url}
          target="_blank"
          rel="noreferrer"
        >
          <Label>{linkData.internalName}</Label>
        </a>
      );
  }
});

function Socials({ data, inverted }) {
  const spacingCx = data.length > 1 ? Styles.spacing : "";
  return (
    <div className={classnames(Styles.socials, spacingCx)}>
      {data.map((social, key) => {
        return <SocialLink data={social} key={key} inverted={inverted} />;
      })}
    </div>
  );
}

export default Socials;
