import { memo, useEffect, useState } from "react";
import { getEntryById } from "../../util/service";
import CoffeeBeanPattern from "../beans";
import Styles from "./index.module.scss";

import classnames from "classnames";

const GalleryImage = memo(function GalleryImage({ photo, className }) {
  const [photoData, setPhotoData] = useState(null);
  useEffect(() => {
    getEntryById(photo.sys.id)
      .then((res) => {
        setPhotoData(res.fields.photo.fields);
      })
      .catch((e) => console.log(e));
  }, [photo]);
  return (
    <div className={classnames(Styles.photoContainer, className)}>
      {photoData && (
        <img
          src={photoData.file.url}
          className={Styles.photo}
          alt={photoData.title}
        />
      )}
    </div>
  );
});

function Gallery({ content }) {
  const additionalMargin = content.length > 5 ? Styles.additionalMargin : "";

  return (
    <>
      <div className={Styles.container}>
        <div className={Styles.content}>
          {content.map((photo, key) => {
            return (
              <GalleryImage
                photo={photo}
                className={additionalMargin}
                key={key}
              />
            );
          })}
        </div>
      </div>
      <CoffeeBeanPattern />
    </>
  );
}

export default Gallery;
