import Styles from "./index.module.scss";
import classnames from "classnames";

// <Heading> Proptypes
// type:  "h1" | "h2"
export const Heading = ({ children, type, className }) => {
  switch (type) {
    case "h2":
      return <h2 className={classnames(Styles.h2, className)}>{children}</h2>;
    case "h1":
    default:
      return <h1 className={classnames(Styles.h1, className)}>{children}</h1>;
  }
};

export const Paragraph = ({ children, size, className }) => {
  const largerText = size === "large" ? Styles.larger : "";

  return (
    <p className={classnames(Styles.p, largerText, className)}>{children}</p>
  );
};

export const Label = ({ children }) => {
  return <p className={Styles.label}>{children}</p>;
};
