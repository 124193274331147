import Styles from "./index.module.scss";
import SecondaryLogo from "../../../assets/secondaryLogo_background.svg";
import { Paragraph } from "../../../common/text";
import Wrapper from "../../../common/layout";
import Socials from "../../../common/socials";

function Footer({ content }) {
  const { hours, contact, location, socials } = content.fields;
  return (
    <div className={Styles.footer}>
      <Wrapper>
        <div className={Styles.container}>
          <div className={Styles.logo}>
            <img src={SecondaryLogo} alt="Beach Break Surf Cafe" />
          </div>
          <div className={Styles.content}>
            <div>
              <Paragraph>{hours}</Paragraph>
              <Paragraph>{contact}</Paragraph>
              <Paragraph>{location}</Paragraph>
            </div>
            <Socials data={socials} inverted={true} />
          </div>
        </div>
      </Wrapper>
    </div>
  );
}

export default Footer;
